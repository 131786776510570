/* ==================== */
/* ヘッダー */
/* ==================== */
import React, { useEffect, useState, useRef, useCallback, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import BottomSheet from '../BottomSheet/BottomSheet';
import Menu from '../Menu/Menu';
import Loading from '../Loading/Loading';
import { RESPONSE, USER_TYPE } from '../../const/Enum';
import { ErrorMessage } from '../../const/Constant';
import { requestApiLoad } from '../../utils/apiLoadUtil';
import './Header.css';
import { UnreadCountContext } from '../../context/UnreadCountContext';

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const menuRef = useRef(null);
  const bottomSheetRef = useRef(null);
  const [userName, setUserName] = useState('');
  const [headerStyle, setHeaderStyle] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { unreadCount, setUnreadCount } = useContext(UnreadCountContext);
  const isDisplay = location.pathname === '/' || location.pathname === '/createAccount/';

  // ------------------------------------------------------------------------------------
  // コールバック
  // ------------------------------------------------------------------------------------
  const fetchUnreadCount = useCallback(async () => {
    if (Number(Cookies.get('role')) === USER_TYPE.ADMIN || Number(Cookies.get('role')) === USER_TYPE.STAFF) {
      const res = await requestApiLoad('/notification/getUnreadCount', {}, setIsLoading);
      if (res.return === RESPONSE.SUCCESS && typeof setUnreadCount === 'function') {
        setUnreadCount(res.unreadCount);
      }
    }
  }, [setUnreadCount]);

  // ------------------------------------------------------------------------------------
  // 初期処理
  // ------------------------------------------------------------------------------------
  useEffect(() => {
    const cookieUserName = Cookies.get('userName');
    const cookieUserRole = Cookies.get('role');
    const getBackgroundColor = (role) => {
      switch (Number(role)) {
        case USER_TYPE.OWNER: return '#9AE5EB';
        case USER_TYPE.ADMIN: return '#F4B7BC';
        case USER_TYPE.STAFF: return '#B7DAF4';
        default: return '#2ea9d5';
      }
    };

    const headerStyle = {
      backgroundColor: getBackgroundColor(cookieUserRole),
    };
    setUserName(cookieUserName);
    setHeaderStyle(headerStyle);
    // eslint-disable-next-line
  }, [Cookies.get('userName'), Cookies.get('role')]);
  
  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if(Number(Cookies.get('role')) === USER_TYPE.ADMIN || Number(Cookies.get('role')) === USER_TYPE.STAFF) {
      fetchUnreadCount();
    }
    // eslint-disable-next-line
  }, [Cookies.get('role')]);

  // ------------------------------------------------------------------------------------
  // マイページ
  // ------------------------------------------------------------------------------------
  const handleMyPageClick = () => {
    setIsMenuOpen(false);
    navigate('/mypage');
  };

  // ------------------------------------------------------------------------------------
  // 通知ページ
  // ------------------------------------------------------------------------------------
  const handleNotificationClick = () => {
    setIsMenuOpen(false);
    navigate('/notification');
  };

  // ------------------------------------------------------------------------------------
  // ログアウト
  // ------------------------------------------------------------------------------------
  const handleLogoutClick = async () => {
    setIsMenuOpen(false);
    if (window.confirm('ログアウトしますか？')) {
      const res = await requestApiLoad('/auth/logout', {}, setIsLoading);
      if (res.return === RESPONSE.PROGRESS) {
        return;
      }
      
      if (res.return === RESPONSE.SUCCESS) {
        Cookies.remove('userName');
        Cookies.remove('role');
        Cookies.remove('sessionId');
        navigate('/');
      } else {
        alert(ErrorMessage.API_ERROR);
      }
    }
  };

  // ------------------------------------------------------------------------------------
  // メニュー
  // ------------------------------------------------------------------------------------
  const openMenu = (event) => {
    event.stopPropagation();
    setIsMenuOpen(!isMenuOpen);
  };
  
  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target) &&
      !event.target.closest('.user-button-menu-content')
    ) {
      setIsMenuOpen(false);
    }
  };
  
  // ------------------------------------------------------------------------------------
  // レンダリング
  // ------------------------------------------------------------------------------------
  return (
    <header className="header" style={headerStyle}>
      <Loading isLoading={isLoading} />
      <BottomSheet bottomSheetRef={bottomSheetRef} />
      <div className="header-contents">
        <img
          className="system-logo"
          src="/images/system-logo.png"
          alt="ロゴ"
          onClick={() => navigate('/')}
        />
        <div className="button-group" style={{ display: isDisplay ? 'none' : 'flex' }}>
          {Number(Cookies.get('role')) !== USER_TYPE.OWNER &&
            <div className="notification">
              <button onClick={handleNotificationClick}>
                <svg
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20px"
                  height="20px"
                  viewBox="0 0 24 24"
                  aria-labelledby="bellIconTitle"
                  stroke="var(--default-font-color)"
                  strokeWidth="1.9"
                  strokeLinecap="square"
                  strokeLinejoin="miter"
                  fill="none"
                  color="var(--default-font-color)">
                  <title id="bellIconTitle">通知</title>
                  <path strokeLinejoin="round" d="M10.5,4.5 C12.1666667,4.5 13.8333333,4.5 15.5,4.5 C17.5,4.5 18.8333333,3.83333333 19.5,2.5 L19.5,18.5 C18.8333333,17.1666667 17.5,16.5 15.5,16.5 C13.8333333,16.5 12.1666667,16.5 10.5,16.5 L10.5,16.5 C7.1862915,16.5 4.5,13.8137085 4.5,10.5 L4.5,10.5 C4.5,7.1862915 7.1862915,4.5 10.5,4.5 Z" transform="rotate(90 12 10.5)" />
                  <path d="M11,21 C12.1045695,21 13,20.1045695 13,19 C13,17.8954305 12.1045695,17 11,17" transform="rotate(90 12 19)" />
                </svg>
                {unreadCount > 0 && <span className="notification-badge">{unreadCount > 99 ? '99+' : unreadCount}</span>}
              </button>
            </div>
          }
          {/* PCモード */}
          <div className="display-group" id="dt-mode">
            <button className="username" onClick={openMenu}>
              {userName || "Unknown"}
            </button>
            {isMenuOpen && (
              <div className="user-button-menu" ref={menuRef}>
                {Number(Cookies.get('role')) === USER_TYPE.STAFF && (
                  <div
                    className="user-button-menu-content"
                    onClick={handleMyPageClick}>
                    <img
                      src="/images/mypage.png"
                      alt="mypage"
                    />
                    <button
                      id="mypage-button"
                      className="user-button-menu-item">
                      マイページ
                    </button>
                  </div>
                )}
                <div
                  className="user-button-menu-content"
                  onClick={handleLogoutClick}>
                  <img
                    src="/images/logout.png"
                    alt="logout"
                  />
                  <button
                    id="logout-button"
                    className="user-button-menu-item">
                    ログアウト
                  </button>
                </div>
              </div>
            )}
          </div>
          
          {/* SPモード */}
          <div className="display-group" id="sp-mode">
            <button className="hamburger-menu" onClick={openMenu}>
              <span className="hamburger-icon"></span>
              {unreadCount > 0 && <span className="notification-dot"></span>}
            </button>
            {isMenuOpen && (
              <div className="user-button-menu" ref={menuRef}>
                <p className="user-name">{userName || "Unknown"}</p>
                {Number(Cookies.get('role')) === USER_TYPE.STAFF && (
                  <div
                    className="user-button-menu-content"
                    onClick={handleMyPageClick}>
                    <img
                      src="/images/mypage.png"
                      alt="mypage"
                    />
                    <button
                      id="mypage-button"
                      className="user-button-menu-item">
                      マイページ
                    </button>
                  </div>
                )}
                {Number(Cookies.get('role')) !== USER_TYPE.OWNER && (
                  <>
                    <div
                      className="user-button-menu-content"
                      onClick={handleNotificationClick}>
                      <svg
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20px"
                        height="20px"
                        viewBox="0 0 24 24"
                        aria-labelledby="bellIconTitle"
                        stroke="var(--default-font-color)"
                        strokeWidth="1.9"
                        strokeLinecap="square"
                        strokeLinejoin="miter"
                        fill="none"
                        color="var(--default-font-color)">
                        <title id="bellIconTitle">通知</title>
                        <path strokeLinejoin="round" d="M10.5,4.5 C12.1666667,4.5 13.8333333,4.5 15.5,4.5 C17.5,4.5 18.8333333,3.83333333 19.5,2.5 L19.5,18.5 C18.8333333,17.1666667 17.5,16.5 15.5,16.5 C13.8333333,16.5 12.1666667,16.5 10.5,16.5 L10.5,16.5 C7.1862915,16.5 4.5,13.8137085 4.5,10.5 L4.5,10.5 C4.5,7.1862915 7.1862915,4.5 10.5,4.5 Z" transform="rotate(90 12 10.5)" />
                        <path d="M11,21 C12.1045695,21 13,20.1045695 13,19 C13,17.8954305 12.1045695,17 11,17" transform="rotate(90 12 19)" />
                      </svg>
                      <button
                        id="notification-button"
                        className="user-button-menu-item">
                        通知一覧
                      </button>
                      {unreadCount > 0 && <span className="notification-dot"></span>}
                    </div>
                    <div className="separate-line" />
                  </>
                )}

                <Menu onClickCallback={()=>setIsMenuOpen(false)} />
                <div className="separate-line" />
                
                <div
                  className="user-button-menu-content"
                  onClick={handleLogoutClick}>
                  <img
                    src="/images/logout.png"
                    alt="logout"
                  />
                  <button
                    id="logout-button"
                    className="user-button-menu-item">
                    ログアウト
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header;