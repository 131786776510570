/* ==================== */
/* （研修設定）研修登録/編集モーダル */
/* ==================== */
import React, { useEffect, useState } from 'react';
import DocumentModal from '../../DocumentModal/DocumentModal';
import Loading from '../../Loading/Loading';
import SelectGroupModal from '../../SelectGroupModal/SelectGroupModal';
import SelectJobModal from '../../SelectJobModal/SelectJobModal';
import StatusFilters from '../../StatusFilters/StatusFilters';
import { StatusMessage } from '../../StatusMessage/StatusMessage';
import TestModal from '../../TestModal/TestModal';
import ToggleSwitch from '../../ToggleSwitch/ToggleSwitch';
import VimeoPlayer from '../../VimeoPlayer/VimeoPlayer';
import { requestApiLoad } from '../../../utils/apiLoadUtil';
import { FILE_TYPE, STATUS_TYPE, PUBLISH_SCOPE, TEST_MODE } from '../../../const/Enum';
import { RESPONSE } from '../../../const/Enum';
import { handleDateBlur as handleDateBlurUtil } from '../../../utils/dateInputUtil';
import { getCurrentDateString } from '../../../utils/dateUtils';
import useWindowSize from '../../../hook/useWindowSize';
import './JobSettingForm.css';

const JobSettingForm = ({ formData, setFormData, isEdit }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSelectGroupModalOpen, setIsSelectGroupModalOpen] = useState(false);
  const [isSelectJobModalOpen, setIsSelectJobModalOpen] = useState(false);
  const [isDocumentModalOpen, setIsDocumentModalOpen] = useState(false);
  const [isTestModalOpen, setIsTestModalOpen] = useState(false);
  const [isSelectJob, setIsSelectJob] = useState(false);
  const [selectJob, setSelectJob] = useState({});
  const [selectGroup, setSelectGroup] = useState({});
  const [selectDocument, setSelectDocument] = useState({});
  const [selectTestData, setSelectTestData] = useState({});
  const statusOptions = [
    { value: PUBLISH_SCOPE.ALL, label: '全員' },
    { value: PUBLISH_SCOPE.LIMITED, label: '限定公開' }
  ];
  const today = getCurrentDateString();
  const size = useWindowSize();

  // ------------------------------------------------------------------------------------
  // 初期処理
  // ------------------------------------------------------------------------------------
  useEffect(() => {
    const selectedJob = async () => {
      // 新規追加時のみ、研修選択後にテストデータを取得
      if (isEdit || !selectJob || Object.keys(selectJob).length === 0) { return; }
      const res = await requestApiLoad(`/test/get/${selectJob.jobId}`, {}, setIsLoading);
      if (res.return !== RESPONSE.SUCCESS) {
        return;
      }
      
      setIsSelectJob(true);
      setFormData(prevFormData => ({
        ...prevFormData,
        ...selectJob,
        test: res.questionList
      }));
    }
    selectedJob();
    // eslint-disable-next-line
  }, [selectJob]);

  useEffect(() => {
    // 新規作成中のみグループ情報を設定
    if (!isEdit && selectGroup.groupId) {
      setFormData(prevFormData => ({
        ...prevFormData,
        groupId: selectGroup.groupId,
        groupName: selectGroup.groupName,
      }));
      setIsSelectGroupModalOpen(false);
    }
    // eslint-disable-next-line
  }, [selectGroup, isEdit]);

  // ------------------------------------------------------------------------------------
  // イベント
  // ------------------------------------------------------------------------------------
  const handleInputChange = (event) => {
    const { name, value, type } = event.target;
    if (type === 'checkbox') {
      setFormData(prevFormData => ({
        ...prevFormData,
        [name]: event.target.checked
      }));

    } else {
      setFormData(prevFormData => ({
        ...prevFormData,
        [name]: value
      }));
    }
  };
  
  const handleDateBlur = (event) => {
    handleDateBlurUtil(event, setFormData);
  };

  // ------------------------------------------------------------------------------------
  // 公開範囲変更イベント
  // ------------------------------------------------------------------------------------
  const handleStatusChange = (event) => {
    const newStatus = Number(event.target.value);
    setFormData((prevFormData) => ({
      ...prevFormData,
      publishScope: newStatus,
    }));

    // 全体公開に変更した場合は、グループ情報をクリア
    if (newStatus === PUBLISH_SCOPE.ALL) {
      setFormData(prevFormData => ({
        ...prevFormData,
        groupId: null,
        groupName: null,
        groupNameKana: null,
      }));
    }
  };

  // ------------------------------------------------------------------------------------
  // ドキュメントモーダル
  // ------------------------------------------------------------------------------------
  const handleDocumentPreview = (e, doc) => {
    e.stopPropagation();
    setSelectDocument(doc);
    setIsDocumentModalOpen(true);
  };
  
  // ------------------------------------------------------------------------------------
  // テストモーダル
  // ------------------------------------------------------------------------------------
  const handleTestPreview = (e, testData) => {
    e.stopPropagation();
    setSelectTestData(testData);
    setIsTestModalOpen(true);
  };


  // ------------------------------------------------------------------------------------
  // レンダリング
  // ------------------------------------------------------------------------------------
  return (
    <>
      <Loading isLoading={isLoading} />
      <div className="modal-form" id="job-setting-form">
        <StatusMessage
          status={formData.status}
          startDate={formData.releaseStartDate}
          endDate={formData.releaseEndDate}
          isOwner={false}
          isAvailable={formData.isAvailable}
          screen='jobSetting'
        />
        {!isEdit && (
          <div className={`${size.width > 480 ? 'width50' : ''}`}>
            <button
              className='select-job-button blue'
              type="button"
              onClick={() => setIsSelectJobModalOpen(true)}
            >
              {isSelectJob && (
                <svg
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20px"
                  height="20px"
                  viewBox="0 0 24 24"
                  aria-labelledby="rotateIconTitle"
                  stroke="#007bff"
                  strokeWidth="2.4"
                  strokeLinecap="square"
                  strokeLinejoin="miter"
                  fill="none"
                  color="#007bff"
                >
                  <title id="rotateIconTitle">変更</title>
                  <path d="M22 12l-3 3-3-3" />
                  <path d="M2 12l3-3 3 3" />
                  <path d="M19.016 14v-1.95A7.05 7.05 0 0 0 8 6.22" />
                  <path d="M16.016 17.845A7.05 7.05 0 0 1 5 12.015V10" />
                  <path strokeLinecap="round" d="M5 10V9" />
                  <path strokeLinecap="round" d="M19 15v-1" />
                </svg>
              )}
              {isSelectJob ? '研修変更' : '研修選択'}
            </button>
          </div>
        )}
        {(isSelectJob || isEdit) && (
          <div className="job-setting-container">
            <div className="column-set">
              <div className="row-set" id="if-name">
                <div className="input-group">
                  <label>研修名</label>
                  <input
                    type="text"
                    name="jobName"
                    value={formData.jobName || ''}
                    onChange={handleInputChange}
                    disabled
                  />
                </div>
              </div>

              <div className="row-set" id="if-dates">
                <div className="input-group">
                  <label>公開開始日</label>
                  <input
                    type="date"
                    name="releaseStartDate"
                    value={formData.status === STATUS_TYPE.ACTIVE ? today : formData.releaseStartDate || ''}
                    onChange={handleInputChange}
                    onBlur={handleDateBlur}
                    disabled
                    pattern="\d{4}-\d{2}-\d{2}"
                    min="1900-01-01"
                    max="2999-12-31"
                  />
                </div>

                <div className="input-group">
                  <label>公開終了日</label>
                  <input
                    type="date"
                    name="releaseEndDate"
                    value={formData.releaseEndDate || ''}
                    onChange={handleInputChange}
                    onBlur={handleDateBlur}
                    disabled
                    pattern="\d{4}-\d{2}-\d{2}"
                    min="1900-01-01"
                    max="2999-12-31"
                  />
                </div>
              </div>
            </div>

            {formData.fileType === FILE_TYPE.VIDEO && formData.vimeoId && (
              <div className='vimeo-player-wrapper'>
                <VimeoPlayer
                  videoId={formData.vimeoId}
                />
              </div>
            )}

            <div className='button-wrapper-group'>
              {formData.fileType === FILE_TYPE.PDF && (
                <div className="pdf-view-button-wrapper">
                  <button
                    className='border-blue'
                    onClick={(e) => handleDocumentPreview(e, {
                      documentName: formData.jobName,
                      documentFilePath: formData.filePath,
                    })}
                  >
                    研修PDFを確認
                  </button>
                </div>
              )}
              {formData.test && formData.test.length > 0 && (
                <div className="pdf-view-button-wrapper">
                  <button
                    className='border-blue'
                    onClick={(e) => handleTestPreview(e, {
                      jobId: formData.jobId,
                      jobTitle: formData.jobName,
                      questionList: formData.test,
                    })}
                  >
                    テストを確認
                  </button>
                </div>
              )}
            </div>
          
            <div className="separate-line"></div>
            <div>
              <p className="separate-title">公開範囲</p>
              <StatusFilters
                key={formData.publishScope}
                statusFilter={formData.publishScope}
                handleStatusChange={handleStatusChange}
                statusOptions={statusOptions}
                disabled={isEdit}
              />
              {Number(formData.publishScope) === PUBLISH_SCOPE.LIMITED && (
                <div>
                  <button
                    className='blue staff-group-select'
                    type="button"
                    onClick={() => setIsSelectGroupModalOpen(true)}
                    disabled={isEdit}
                  >
                    {(formData.groupId && formData.groupName) || '公開グループ選択'}
                  </button>
                </div>
              )}

              <div className="spacer30"></div>
            
              <div className="toggle-group">
                {formData.fileType === FILE_TYPE.PDF && (
                  <ToggleSwitch
                    name="isDownload"
                    checked={formData.isDownload ?? false}
                    onChange={handleInputChange}
                    disabled={isEdit || !selectJob.isDownload}
                    label="PDFのダウンロードを許可"
                  />
                )}
                <ToggleSwitch
                  name="isAllowReview"
                  checked={formData.isAllowReview ?? false}
                  onChange={handleInputChange}
                  disabled={isEdit}
                  label="研修の複数回受講を許可"
                />
                <ToggleSwitch
                  name="isTest"
                  checked={formData.isTest ?? false}
                  onChange={handleInputChange}
                  disabled={isEdit || !selectJob.isTest}
                  label="テストを実施する"
                />
                {formData.isTest && (
                  <ToggleSwitch
                    name="isAllowRetest"
                    checked={formData.isAllowRetest ?? false}
                    onChange={handleInputChange}
                    disabled={isEdit || !selectJob.isTest}
                    label="テストの複数回実施を許可"
                  />
                )}
              </div>
            </div>
          </div>
        )}

        <SelectJobModal
          isOpen={isSelectJobModalOpen}
          setIsOpen={setIsSelectJobModalOpen}
          setSelectJob={setSelectJob}
        />

        <SelectGroupModal
          isOpen={isSelectGroupModalOpen}
          setIsOpen={setIsSelectGroupModalOpen}
          setSelectGroup={setSelectGroup}
          useNoGroup={false}
        />

        <DocumentModal
          isOpen={isDocumentModalOpen}
          closeModal={() => setIsDocumentModalOpen(false)}
          title={selectDocument.documentName}
          filePath={selectDocument.documentFilePath}
          isDownload={formData.isDownload}
          isDownloadDisp={formData.isDownload}
          isPreview={true}
        />
      
        {isTestModalOpen &&
          <TestModal
            jobId={selectTestData.jobId}
            jobTitle={selectTestData.jobTitle}
            testData={selectTestData.questionList}
            isOpen={isTestModalOpen}
            closeModal={() => setIsTestModalOpen(false)}
            mode={TEST_MODE.PREVIEW}
          />
        }
      </div>
    </>
  )
}

export default JobSettingForm;