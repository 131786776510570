/* ==================== */
/* 通知設定画面 */
/* ==================== */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import Cookies from 'js-cookie';
import BottomSheet from '../../components/BottomSheet/BottomSheet';
import FilterButton from '../../components/FilterButton/FilterButton';
import Pagination from '../../components/Pagination/Pagination';
import Loading from '../../components/Loading/Loading';
import Modal from '../../components/Modal/Modal';
import NotificationSettingForm from '../../components/Form/NotificationSettingForm/NotificationSettingForm';
import SortingButton from '../../components/SortingButton/SortingButton';
import useWindowSize from '../../hook/useWindowSize';
import { READ_STATUS, RESPONSE, SEND_TO_TYPE, SEND_TO_TYPE_ADMIN, SEND_TO_TYPE_OWNER, USER_TYPE } from '../../const/Enum';
import { requestApiLoad, requestApiLoadAndBottom } from '../../utils/apiLoadUtil';
import * as Validators from '../../utils/validation';
import './NotificationSetting.css';

const NotificationSetting = () => {
  const [itemList, setItemList] = useState([]);
  const [addItem, setAddItem] = useState([]);
  const [detailItem, setDetailItem] = useState([]);
  const [isInit, setIsInit] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState([]);
  const bottomSheetRef = useRef(null);
  const size = useWindowSize();

  /* メイン一覧画面 */
  const [mainSearchTerm, setMainSearchTerm] = useState('');
  const [mainStatusFilter, setMainStatusFilter] = useState(SEND_TO_TYPE_OWNER.ALL);
  const [mainSortConfig, setMainSortConfig] = useState({ key: 'notificationId', direction: true });
  const [mainTotalCount, setMainTotalCount] = useState(0);
  const [mainLastPage, setMainLastPage] = useState(1);
  const [mainCurrentPage, setMainCurrentPage] = useState(1);
  const [mainStatusOptions, setMainStatusOptions] = useState([
    { value: SEND_TO_TYPE_OWNER.ALL, label: '全員' },
    { value: SEND_TO_TYPE_OWNER.ADMIN, label: '管理者対象' },
  ]);
  const mainSortOptions = [
    { key: 'notificationId', value: { key: 'notificationId', direction: true }, label: '標準' },
    { key: 'date_asc', value: { key: 'date', direction: false }, label: '日付（昇順）' },
    { key: 'date_desc', value: { key: 'date', direction: true }, label: '日付（降順）' }
  ];
  
  /* サブ一覧画面 */
  const [subSearchTerm, setSubSearchTerm] = useState('');
  const [subStatusFilter, setSubStatusFilter] = useState(0);
  const [subSortConfig, setSubSortConfig] = useState({ key: 'userId', direction: true });
  const [subTotalCount, setSubTotalCount] = useState(0);
  const [subLastPage, setSubLastPage] = useState(1);
  const [subCurrentPage, setSubCurrentPage] = useState(1);
  const [subSearchTerm_TypeName, setSubSearchTerm_TypeName] = useState('管理者名');
  const subSearchTerm_MaxLength = 20;
  const subStatusOptions = [
    { value: READ_STATUS.ALL, label: '全て' },
    { value: READ_STATUS.UNREAD, label: '未読' },
    { value: READ_STATUS.READ, label: '既読' }
  ];
  const [subSortOptions, setSubSortOptions] = useState([
    { key: 'userId', value: { key: 'userId', direction: true }, label: '標準' },
    { key: 'companyName_asc', value: { key: 'companyName', direction: false }, label: '会社名（昇順）' },
    { key: 'companyName_desc', value: { key: 'companyName', direction: true }, label: '会社名（降順）' },
  ]);

  /* 画面状態 */
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showDetail, setShowDetail] = useState(false);

  // ------------------------------------------------------------------------------------
  // 初期処理
  // ------------------------------------------------------------------------------------
  useEffect(() => {
    if (Number(Cookies.get('role')) === USER_TYPE.OWNER) {
      setSubSearchTerm_TypeName('管理者名');
      setMainStatusOptions([
        { value: SEND_TO_TYPE_OWNER.ALL, label: 'すべて' },
        { value: SEND_TO_TYPE_OWNER.ALL_USER, label: '全ユーザー対象' },
        { value: SEND_TO_TYPE_OWNER.ADMIN, label: '管理者対象' },
      ]);
      setSubSortOptions([
        { key: 'userId', value: { key: 'userId', direction: true }, label: '標準' },
        { key: 'companyName_asc', value: { key: 'companyName', direction: false }, label: '会社名（昇順）' },
        { key: 'companyName_desc', value: { key: 'companyName', direction: true }, label: '会社名（降順）' },
      ]);

    } else {
      setSubSearchTerm_TypeName('スタッフ名');
      setMainStatusOptions([
        { value: SEND_TO_TYPE_ADMIN.ALL, label: 'すべて' },
        { value: SEND_TO_TYPE_ADMIN.STAFF, label: '全スタッフ対象' },
        { value: SEND_TO_TYPE_ADMIN.GROUP, label: 'グループ対象' },
        { value: SEND_TO_TYPE_ADMIN.PERSONAL, label: '個人対象' },
      ]);
      setSubSortOptions([
        { key: 'userId', value: { key: 'userId', direction: true }, label: '標準' },
        { key: 'groupId_asc', value: { key: 'groupId', direction: false }, label: 'グループID（昇順）' },
        { key: 'groupId_desc', value: { key: 'groupId', direction: true }, label: 'グループID（降順）' },
      ]);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const params = {
        page: 1,
        isDesc: mainSortConfig.direction,
        orderKey: mainSortConfig.key,
        sendType: mainStatusFilter,
        name: mainSearchTerm,
      };
  
      const res = await requestApiLoad('/notificationSetting/get', params, setIsLoading);
      if (res.return !== RESPONSE.SUCCESS) {
        return;
      }
  
      resetSubScreen();

      setSelectedItem((res.notificationList && res.notificationList.length > 0) ? res.notificationList[0] : []);
      setItemList((res.notificationList && res.notificationList.length > 0) ? res.notificationList : []);
      setMainCurrentPage(1);
      setMainLastPage(res.lastPage);
      setMainTotalCount(res.dataCount);
    };
    
    fetchData();
  }, [mainStatusFilter, mainSortConfig.direction, mainSortConfig.key, mainSearchTerm]);

  useEffect(() => {
    const fetchSubData = async () => {
      if (!selectedItem || selectedItem.length === 0 || !showDetail) {
        return;
      }

      const params = {
        page: 1,
        isDesc: subSortConfig.direction,
        orderKey: subSortConfig.key,
        status: subStatusFilter,
        name: subSearchTerm,
      }
  
      const res = await requestApiLoad(`/notificationSetting/getDetail/${selectedItem.notificationId}`, params, setIsLoading);
      if (res.return !== RESPONSE.SUCCESS) {
        return;
      }

      setDetailItem(res.userList);
      setSubCurrentPage(1);
      setSubLastPage(res.lastPage);
      setSubTotalCount(res.dataCount);
    }

    fetchSubData();
    // eslint-disable-next-line
  }, [selectedItem, subStatusFilter, subSortConfig.direction, subSortConfig.key, subSearchTerm]);

  useEffect(() => {
    const subInit = async () => {
      if (!isInit) {
        return;
      }

      if (itemList.length > 0) {
        if (size.width > 1200) {
          changeScreenMode(MODE.EDIT);
        } else {
          changeScreenMode(MODE.NONE);
        }
        setIsInit(false);
      }
    }
    
    subInit();
    // eslint-disable-next-line
  }, [itemList]);

  // ------------------------------------------------------------------------------------
  // 画面モード
  // ------------------------------------------------------------------------------------
  const MODE = {
    NONE: 'NONE',
    ADD: 'ADD',
    EDIT: 'EDIT',
    DETAIL: 'DETAIL',
  }

  const changeScreenMode = (mode) => {
    switch (mode) {
      case MODE.ADD:
        setShowAdd(true);
        setShowEdit(false);
        setShowDetail(false);
        break;
      
      case MODE.EDIT:
        setShowAdd(false);
        setShowEdit(true);
        setShowDetail(false);
        break;
      
      case MODE.DETAIL:
        setShowAdd(false);
        setShowEdit(false);
        setShowDetail(true);
        break;
      
      case MODE.NONE:
      default:
        setShowAdd(false);
        setShowEdit(false);
        setShowDetail(false);
        break; 
    }
  }

  const resetSubScreen = () => {
    setSubCurrentPage(1);
    setSubSearchTerm('');
    setSubStatusFilter(READ_STATUS.ALL);
    setSubSortConfig({ key: 'userId', direction: true });
  }

  // ------------------------------------------------------------------------------------
  // ページネーション
  // ------------------------------------------------------------------------------------
  const handleMainPageChange = async (pageNumber) => {
    setMainCurrentPage(pageNumber);

    const params = {
      page: pageNumber,
      isDesc: mainSortConfig.direction,
      orderKey: mainSortConfig.key,
      sendType: mainStatusFilter,
      name: mainSearchTerm,
    }

    const res = await requestApiLoad('/notificationSetting/get', params, setIsLoading);
    if (res.return !== RESPONSE.SUCCESS) {
      return;
    }
    
    setItemList(res.notificationList);
    setSelectedItem(res.notificationList[0]);
    setMainLastPage(res.lastPage);
    setMainTotalCount(res.dataCount);
  }

  const handleSubPageChange = async (pageNumber) => {
    setSubCurrentPage(pageNumber);
    
    const params = {
      page: 1,
      isDesc: subSortConfig.direction,
      orderKey: subSortConfig.key,
      status: subStatusFilter,
      name: subSearchTerm,
    }

    const res = await requestApiLoad(`/notificationSetting/getDetail/${selectedItem.notificationId}`, params, setIsLoading);
    if (res.return !== RESPONSE.SUCCESS) {
      return;
    }

    setDetailItem(res.userList);
    setSubLastPage(res.lastPage);
    setSubTotalCount(res.dataCount);
  }

  // ------------------------------------------------------------------------------------
  // フィルター
  // ------------------------------------------------------------------------------------
  const handleMainStatusChange = async (event) => {
    const newStatusFilter = !event.target.value ? 1 : Number(event.target.value);
    setMainCurrentPage(1);
    setMainStatusFilter(newStatusFilter);
  };

  const handleSubStatusChange = async (event) => {
    const newStatusFilter = !event.target.value ? READ_STATUS.ALL : Number(event.target.value);
    setSubCurrentPage(1);
    setSubStatusFilter(newStatusFilter);
  };

  const sortedAndFilteredItems = useMemo(() => {
    let filteredItems = itemList || [];
    return filteredItems;
  }, [itemList]);

  // ------------------------------------------------------------------------------------
  // ソート
  // ------------------------------------------------------------------------------------
  const handleMainSortChange = async (event) => {
    const newSortConfig = !event ? { key: 'notificationId', direction: true } : JSON.parse(event);
    setMainSortConfig(newSortConfig);
  };

  const handleSubSortChange = async (event) => {
    const newSortConfig = !event ? { key: 'userId', direction: true } : JSON.parse(event);
    setSubSortConfig(newSortConfig);
  };

  // ------------------------------------------------------------------------------------
  // 検索
  // ------------------------------------------------------------------------------------
  const onMainClear = () => {
    setMainSearchTerm('');
  };

  const onSubClear = () => {
    setSubSearchTerm('');
  };

  // ------------------------------------------------------------------------------------
  // 新規追加
  // ------------------------------------------------------------------------------------
  const handleAdd = async () => {
    setAddItem({
      title: '',
      contents: '',
      sendType: Number(Cookies.get('role')) === USER_TYPE.OWNER ? SEND_TO_TYPE.ALL : SEND_TO_TYPE.STAFF,
      sendToIds: [],
    });
    
    setShowAdd(true);
    setShowEdit(false);
    setShowDetail(false);
  }

  const handleAddCancel = async (event) => {
    event.preventDefault();
    
    setItemList(itemList);
    setMainLastPage(mainLastPage);
    setMainTotalCount(mainTotalCount);
    setSelectedItem(itemList[0]);

    if (!itemList || itemList?.length === 0) {
      setSelectedItem({});
      changeScreenMode(MODE.NONE);
    } else if (size.width <= 1200) {
      changeScreenMode(MODE.NONE);
    } else {
      changeScreenMode(MODE.EDIT);
    }
  }

  const handleAddSubmit = async (event) => {
    event.preventDefault();    
    
    let sendToIds = [];
    if (Number(addItem.sendType) === SEND_TO_TYPE.GROUP) {
      sendToIds = addItem.sendToIds.map(item => parseInt(item.groupId));
    } else if (Number(addItem.sendType) === SEND_TO_TYPE.PERSONAL) {
      sendToIds = addItem.sendToIds.map(item => parseInt(item.staffId));
    }
    const params = {
      title: addItem.title,
      contents: addItem.contents,
      sendType: Number(addItem.sendType),
      sendToIds: sendToIds,
    }

    // ValidationCheck
    if (ValidationCheck(params).error) {
      alert(ValidationCheck(params).message);
      return;
    }

    const res = await requestApiLoadAndBottom('/notificationSetting/add',
      params, setIsLoading, bottomSheetRef, '通知を送信しました。');
    if (res.return !== RESPONSE.SUCCESS) {
      return;
    }
    resetSubScreen();
    setFirstItem(res);

    if (size.width <= 1200) {
      changeScreenMode(MODE.NONE);
    } else {
      changeScreenMode(MODE.EDIT);
    }
  }
  // ------------------------------------------------------------------------------------
  // 編集
  // ------------------------------------------------------------------------------------
  const handleEdit = async (e, item) => {
    if (e && e.stopPropagation) {
      e.stopPropagation();
    }

    if (!item) {
      return;
    }
    setSelectedItem(item);
    changeScreenMode(MODE.EDIT);
  };

  // ------------------------------------------------------------------------------------
  // 詳細
  // ------------------------------------------------------------------------------------
  const handleDetail = async (e, item) => {
    e.stopPropagation();
    
    if (!item) {
      return;
    }
    resetSubScreen();
    setSelectedItem(item);
    changeScreenMode(MODE.DETAIL);
  };

  // ------------------------------------------------------------------------------------
  // その他
  // ------------------------------------------------------------------------------------ 
  const setFirstItem = (res) => { 
    setItemList(res.notificationList);
    setMainLastPage(res.lastPage);
    setMainTotalCount(res.dataCount);
    setSelectedItem(res.notificationList[0]);
    // setEditItem(res.notificationList[0]);
    
    setMainCurrentPage(1);
    setMainStatusFilter(Number(SEND_TO_TYPE_OWNER.ALL));
    setMainSearchTerm('');
    setMainSortConfig({ key: 'notificationId', direction: true });
    resetScroll();
    resetScrollMain();
  }

  const resetScroll = () => {
    const scrollForm = document.querySelector('.scroll-form');
    if (scrollForm) {
      scrollForm.scrollTop = 0;
    }
  }

  const resetScrollMain = () => {
    const scrollForm = document.querySelector('.card-list');
    if (scrollForm) {
      scrollForm.scrollTop = 0;
    }
  }

  // ------------------------------------------------------------------------------------
  // ValidationCheck
  // ------------------------------------------------------------------------------------
  const ValidationCheck = (params) => {
    // 通知タイトル
    const titleResult = Validators.validateNotificationTitle(params.title);
    if (titleResult.error) {
      return { error: true, message: titleResult.message };
    }
    // 通知内容
    const contentsResult = Validators.validateNotificationContents(params.contents);
    if (contentsResult.error) {
      return { error: true, message: contentsResult.message };
    }
    if(params.sendType === SEND_TO_TYPE.GROUP || params.sendType === SEND_TO_TYPE.PERSONAL) {
      const sendToResult = Validators.validateNotificationSendTo(params.sendToIds, params.sendType);
      if (sendToResult.error) {
        return { error: true, message: sendToResult.message };
      }
    }
    return { error: false, message: '' };
  }

  // ------------------------------------------------------------------------------------
  // 一覧作成
  // ------------------------------------------------------------------------------------
  const createTable = (itemList) => {
    return (
      <div className="card-list">
        {itemList.length === 0 ? (
          <div className="no-data">データがありません</div>
        ) : (
          itemList.map((item) => (
            <div className="card" key={item.notificationId} onClick={(e) => handleEdit(e, item)}>
              <div className="card-contents" id="notification-setting-card-contents">
                <div className="card-contents-left">
                  <h3>{item.title}</h3>
                  <span className="sub-text">{item.date}</span>
                </div>
                <div className="card-footer">
                  {/* 詳細 */}
                  <button className="blue" onClick={(e) => handleDetail(e,item)} title="詳細">
                    <svg
                      width="20px"
                      height="20px"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-labelledby="pollIconTitle"
                      stroke="var(--blue-font-color)"
                      strokeWidth="1.8"
                      strokeLinecap="square"
                      strokeLinejoin="miter"
                      color="var(--blue-font-color)">
                      <title id="pollIconTitle">詳細</title>
                      <path d="M4 4V20" />
                      <path d="M4 6H15V10H4" />
                      <path d="M4 10H19V14H4" />
                      <path d="M4 14H12V18H4" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    );
  };

  // ------------------------------------------------------------------------------------
  // AddTable
  // ------------------------------------------------------------------------------------
  const createAddTable = (addItem) => {
    return (
      <>
        <div className="scroll-form">
          <NotificationSettingForm
            formData={addItem}
            setFormData={setAddItem}
            isEdit={false}
            role={Number(Cookies.get('role')) === USER_TYPE.OWNER ? USER_TYPE.OWNER : USER_TYPE.ADMIN}
          />
        </div>
        <div className="bottom-button">
          <button onClick={handleAddCancel}>
            キャンセル
          </button>
          <button className="blue" onClick={handleAddSubmit}>
            送信
          </button>
        </div>
      </>
    )
  }

  // ------------------------------------------------------------------------------------
  // EditTable
  // ------------------------------------------------------------------------------------
  const createEditTable = () => {
    return (
      <>
        <div className="scroll-form">
          <NotificationSettingForm
            formData={selectedItem}
            setFormData={setSelectedItem}
            isEdit={true}
            role={Number(Cookies.get('role')) === USER_TYPE.OWNER ? USER_TYPE.OWNER : USER_TYPE.ADMIN}
          />
        </div>
      </>
    )
  }

  // ------------------------------------------------------------------------------------
  // DetailTable
  // ------------------------------------------------------------------------------------
  const createDetailTable = () => {
    return (
      <div className="card-list">
        {!selectedItem || selectedItem.length === 0 ||
          !detailItem || detailItem.length === 0 || !Array.isArray(detailItem) ? (
          <div className="no-data">データがありません</div>
        ) : (
          detailItem.map((item) => (
            <div className="card" key={item.userId}>
              <div className="card-contents">
                <div className="card-contents-left">
                  <h3>{item.userName}</h3>

                  {Cookies.get('role') === USER_TYPE.OWNER ? (
                    <span className="sub-text">
                      {!item.role || item.role === USER_TYPE.STAFF ? 'スタッフ' : '管理者'}
                    </span>
                  ) : (
                    <span className="sub-text">
                      {item.companyName}
                    </span>
                  )}

                </div>
                <div className="card-footer">
                  {item.isRead &&
                    <svg
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20px"
                      height="20px"
                      viewBox="0 0 24 24"
                      aria-labelledby="circleOkIconTitle"
                      stroke="var(--blue-font-color)"
                      strokeWidth="2.0"
                      strokeLinecap="square"
                      strokeLinejoin="miter"
                      fill="none"
                      color="var(--blue-font-color)">
                      <title id="circleOkIconTitle">既読</title>
                      <polyline points="7 13 10 16 17 9" />
                      <circle cx="12" cy="12" r="10" />
                    </svg>
                  }
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    )
  }

  // ------------------------------------------------------------------------------------
  // レンダリング
  // ------------------------------------------------------------------------------------
  return (
    <div className="view-contents" id="notification-setting">
      <Loading isLoading={isLoading} />
      <BottomSheet ref={bottomSheetRef} />

      <div className="main-contents">
        {size.width > 1200 && (<h2 className="page-title">通知設定</h2>)}
        <div className="header-contents">
          <div className="search-bar">
            <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
              <input
                type="text"
                placeholder="タイトルで検索"
                value={mainSearchTerm}
                onChange={(e) => setMainSearchTerm(e.target.value)}
                maxLength={20}
                style={{
                  backgroundImage: 'url(/images/search.png)',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: '10px center',
                  backgroundSize: '20px 20px',
                  paddingLeft: '40px',
                  paddingRight: mainSearchTerm ? '30px' : '10px'
                }}
              />
              {mainSearchTerm && (
                <button
                  onClick={onMainClear}
                  style={{
                    position: 'absolute',
                    right: '10px',
                    background: 'none',
                    border: 'none',
                    cursor: 'pointer',
                    fontSize: '16px',
                    color: '#878787'
                  }}>
                  ×
                </button>
              )}
            </div>
          </div>
          <div className="button-container">
            <div className="add-new-item-button" onClick={handleAdd}>
              <svg
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                width="18px"
                height="18px"
                viewBox="0 0 24 24"
                aria-labelledby="plusIconTitle"
                stroke="var(--blue-font-color)"
                strokeWidth="1.8"
                strokeLinecap="square"
                strokeLinejoin="miter"
                fill="none"
                color="var(--blue-font-color)"
              >
                <title id="plusIconTitle">新規追加</title>
                <path d="M20 12L4 12M12 4L12 20" />
              </svg>
              <p className="hide-option">新規追加</p>
            </div>
          </div>
        </div>

        <div className="middle-contents">
          <div className="left-contents">
            <FilterButton
              statusFilter={mainStatusFilter}
              handleStatusChange={handleMainStatusChange}
              statusOptions={mainStatusOptions}
            />
            <SortingButton
              sortConfig={mainSortConfig}
              handleSortChange={handleMainSortChange}
              sortOptions={mainSortOptions}
            />
          </div>
          
          <Pagination
            totalPages={mainLastPage}
            currentPage={mainCurrentPage}
            onPageChange={handleMainPageChange}
            totalItems={mainTotalCount}
          />
        </div>

        {createTable(sortedAndFilteredItems)}
      </div>
      
      {size.width > 1200 ? (
        <>
          {showAdd && (
            <div className="sub-contents">
              <h2 className="page-title">通知追加</h2>
              {createAddTable(addItem)}
            </div>
          )}
          {showEdit && (
            <div className="sub-contents">
              <h2 className="page-title">{selectedItem.title}</h2>
              {createEditTable(selectedItem)}
            </div>
          )}
          {showDetail && (
            <div className="sub-contents">
              <h2 className="page-title">{selectedItem.title}</h2>
              <div className="header-contents">
                <div className="search-bar">
                  <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                    <input
                      type="text"
                      placeholder={subSearchTerm_TypeName}
                      value={subSearchTerm}
                      onChange={(e) => setSubSearchTerm(e.target.value)}
                      maxLength={subSearchTerm_MaxLength}
                      style={{
                        backgroundImage: 'url(/images/search.png)',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: '10px center',
                        backgroundSize: '20px 20px',
                        paddingLeft: '40px',
                        paddingRight: subSearchTerm ? '30px' : '10px'
                      }}
                    />
                    {subSearchTerm && (
                      <button
                        onClick={onSubClear}
                        style={{
                          position: 'absolute',
                          right: '10px',
                          background: 'none',
                          border: 'none',
                          cursor: 'pointer',
                          fontSize: '16px',
                          color: '#878787'
                        }}>
                        ×
                      </button>
                    )}
                  </div>
                </div>
              </div>

              <div className="middle-contents">
                <div className="left-contents">
                  <FilterButton
                    statusFilter={subStatusFilter}
                    handleStatusChange={handleSubStatusChange}
                    statusOptions={subStatusOptions}
                  />
                  <SortingButton
                    sortConfig={subSortConfig}
                    handleSortChange={handleSubSortChange}
                    sortOptions={subSortOptions}
                  />
                </div>
                <Pagination
                  totalPages={subLastPage}
                  currentPage={subCurrentPage}
                  onPageChange={handleSubPageChange}
                  totalItems={subTotalCount}
                />
              </div>

              {createDetailTable()}
            </div>
          )}
        </>
      ) : (
        <>
          <Modal
            isOpen={showAdd}
            title="通知追加"
            onAction={handleAddSubmit}
            actionButtonText="送信"
            closeModal={() => setShowAdd(false)}
            closeButtonText="閉じる"
          >
            <div className="sub-contents">
              <div>
                  
              </div>
              {createAddTable(addItem)}
            </div>
          </Modal>
            
          <Modal
            isOpen={showEdit}
            title={selectedItem.title}
            closeModal={() => setShowEdit(false)}
            closeButtonText="閉じる"
          >
            <div className="sub-contents">
              <div>
                  
              </div>
              {createEditTable(selectedItem)}
            </div>
          </Modal>
            
          <Modal
            isOpen={showDetail}
            title={selectedItem.title}
            closeModal={() => setShowDetail(false)}
            closeButtonText="閉じる"
          >
            <div className="sub-contents">
              <div className="header-contents">
                <div className="search-bar">
                  <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                    <input
                      type="text"
                      placeholder={subSearchTerm_TypeName}
                      value={subSearchTerm}
                      onChange={(e) => setSubSearchTerm(e.target.value)}
                      maxLength={subSearchTerm_MaxLength}
                      style={{
                        backgroundImage: 'url(/images/search.png)',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: '10px center',
                        backgroundSize: '20px 20px',
                        paddingLeft: '40px',
                        paddingRight: subSearchTerm ? '30px' : '10px'
                      }}
                    />
                    {subSearchTerm && (
                      <button
                        onClick={onSubClear}
                        style={{
                          position: 'absolute',
                          right: '10px',
                          background: 'none',
                          border: 'none',
                          cursor: 'pointer',
                          fontSize: '16px',
                          color: '#878787'
                        }}>
                        ×
                      </button>
                    )}
                  </div>
                </div>
              </div>

              <div className="middle-contents">
                <div className="left-contents">
                  <FilterButton
                    statusFilter={subStatusFilter}
                    handleStatusChange={handleSubStatusChange}
                    statusOptions={subStatusOptions}
                  />
                  <SortingButton
                    sortConfig={subSortConfig}
                    handleSortChange={handleSubSortChange}
                    sortOptions={subSortOptions}
                  />
                </div>
                <Pagination
                  totalPages={subLastPage}
                  currentPage={subCurrentPage}
                  onPageChange={handleSubPageChange}
                  totalItems={subTotalCount}
                />
              </div>
              {createDetailTable()}
            </div>
          </Modal>
        </>
      )}
    </div>
  )
}

export default NotificationSetting;