import React from 'react';
import './MatterportViewer.css';

class MatterportViewer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mpSdk: null,
      tagSid: null
    };
  }

  async setupTags(sdk) {
    try {
      // 既存のタグを取得してログ出力
      const tags = await sdk.Mattertag.getData();
      console.log('既存のタグ:', tags);

      const tag = await sdk.Mattertag.add({
        label: "【トリアージタッグの構造】",
        description: "ここをクリックして研修動画を視聴",
        anchorPosition: {
          x: -46.3953056894843,
          y: -3.064309534426692,
          z: -11.857583993638075
        },
        stemVector: {
          x: 0.0005140239564138408,
          y: 1.3173412073809885,
          z: -0.0019564523457893065
        },
        color: { r: 1, g: 0, b: 0 }
      });

      const tagSid = tag.sid;
      this.setState({ tagSid }, () => {
        sdk.on(sdk.Mattertag.Event.CLICK, (clickedTagSid) => {
          console.log('Tag clicked:', clickedTagSid);
          console.log('Stored tagSid:', tagSid);
          
          //とりあえず、タグは1つしかないので、ここではonTagClickを呼び出す
          // if (clickedTagSid === tagSid && this.props.onTagClick) {
            console.log('MatterportViewer: Calling onTagClick');
            this.props.onTagClick(clickedTagSid);
          // }
        });
      });

    } catch (error) {
      console.error('タグの設定エラー:', error);
    }
  }

  componentDidMount() {
    const loadSdk = async () => {
      try {
        const iframe = document.querySelector('iframe');
        if (!iframe) {
          console.error('iframeが見つかりません');
          return;
        }
        
        const sdk = await window.MP_SDK.connect(iframe, process.env.REACT_APP_MATTERPORT_SDK_KEY, '');
        this.setState({ mpSdk: sdk });
        await this.setupTags(sdk);
      } catch (error) {
        console.error('Matterport SDKの初期化エラー:', error);
      }
    };

    setTimeout(loadSdk, 2000);
  }

  render() {
    return (
      <div style={{ width: '100%', height: '100%' }}>
        <iframe
          width="100%"
          height="100%"
          src={`https://my.matterport.com/show/?m=${this.props.modelId}`}
          frameBorder="0"
          allowFullScreen
          allow="xr-spatial-tracking"
        />
      </div>
    );
  }
}

export default MatterportViewer;