/* ==================== */
/* スタッフ設定画面 */
/* ==================== */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import ReactDOM from 'react-dom/client';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { unparse } from 'papaparse';
import BottomSheet from '../../components/BottomSheet/BottomSheet';
import FilterButton from '../../components/FilterButton/FilterButton';
import Loading from '../../components/Loading/Loading';
import Modal from '../../components/Modal/Modal';
import OutputButton from '../../components/OutputButton/OutputButton';
import Pagination from '../../components/Pagination/Pagination';
import StaffList from '../../components/PDFLayout/StaffList/StaffList';
import SortingButton from '../../components/SortingButton/SortingButton';
import StaffSettingForm from '../../components/Form/StaffSettingForm/StaffSettingForm';
import useWindowSize from '../../hook/useWindowSize';
import { FILE_TYPE, JOB_STATUS, RESPONSE, SEX_TYPE, STAFF_STATUS, STATUS_TYPE, TEST_STATUS, USER_TYPE } from '../../const/Enum';
import { Message } from '../../const/Constant';
import { requestApiLoad, requestApiLoadAndBottom } from '../../utils/apiLoadUtil';
import { getNextYearDateString, getCurrentDateString } from '../../utils/dateUtils';
import { formatPostalCode } from '../../utils/formatUtil';
import * as Validators from '../../utils/validation';
import './StaffSetting.css';

const StaffSetting = () => {
  const [itemList, setItemList] = useState([]);
  const [addItem, setAddItem] = useState({});
  const [reportItem, setReportItem] = useState({
    staffId: '',
    staffName: '',
    jobList: [],
  });
  const [selectedItem, setSelectedItem] = useState({});
  const [isInit, setIsInit] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  
  const bottomSheetRef = useRef(null);
  const size = useWindowSize();
  const nextYearToday = getNextYearDateString();
  const today = getCurrentDateString();

  /* メイン一覧画面 */
  const [mainSearchTerm, setMainSearchTerm] = useState('');
  const [mainStatusFilter, setMainStatusFilter] = useState(0);
  const [mainSortConfig, setMainSortConfig] = useState({ key: 'staffId', direction: true });
  const [mainCurrentPage, setMainCurrentPage] = useState(1);
  const [mainLastPage, setMainLastPage] = useState(1);
  const [mainTotalCount, setMainTotalCount] = useState(0);
  const mainStatusOptions = [
    { value: STAFF_STATUS.ALL, label: 'すべて' },
    { value: STAFF_STATUS.ACTIVE, label: '利用中' },
    { value: STAFF_STATUS.SCHEDULED, label: '準備中' },
    { value: STAFF_STATUS.INACTIVE, label: '停止中' }
  ];
  const mainSortOptions = [
    { key: 'staffId', value: { key: 'staffId', direction: true }, label: '標準' },
    { key: 'staffName_asc', value: { key: 'staffName', direction: false }, label: 'スタッフ名（昇順）' },
    { key: 'staffName_desc', value: { key: 'staffName', direction: true }, label: 'スタッフ名（降順）' },
    { key: 'startDate_asc', value: { key: 'startDate', direction: false }, label: '利用開始日（昇順）' },
    { key: 'startDate_desc', value: { key: 'startDate', direction: true }, label: '利用開始日（降順）' },
  ];

  /* サブ画面 */
  const [subSearchTerm, setSubSearchTerm] = useState('');
  const [subStatusFilter, setSubStatusFilter] = useState(0);
  const [subSortConfig, setSubSortConfig] = useState({ key: 'jobId', direction: true });
  const [subCurrentPage, setSubCurrentPage] = useState(1);
  const [subLastPage, setSubLastPage] = useState(1);
  const [subTotalCount, setSubTotalCount] = useState(0);
  const subStatusOptions = [
    { value: JOB_STATUS.ALL, label: 'すべて' },
    { value: JOB_STATUS.NOT_END, label: '未受講' },
    { value: JOB_STATUS.END, label: '受講済' }
  ];
  const subSortOptions = [
    { key: 'jobId', value: { key: 'jobId', direction: true }, label: '標準' },
    { key: 'correctCount_asc', value: { key: 'correctCount', direction: false }, label: '正解数（昇順）' },
    { key: 'correctCount_desc', value: { key: 'correctCount', direction: true }, label: '正解数（降順）' },
    { key: 'correctRate_asc', value: { key: 'correctRate', direction: false }, label: '正解率（昇順）' },
    { key: 'correctRate_desc', value: { key: 'correctRate', direction: true }, label: '正解率（降順）' }
  ];

  /* 画面状態 */
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showReport, setShowReport] = useState(false);

  // ------------------------------------------------------------------------------------
  // 初期処理
  // ------------------------------------------------------------------------------------
  useEffect(() => {
    const fetchData = async () => {
      const params = {
        page: 1,
        isDesc: mainSortConfig.direction,
        orderKey: mainSortConfig.key,
        status: mainStatusFilter,
        name: mainSearchTerm,
      }
      const res = await requestApiLoad('/staff/get', params, setIsLoading);
      if (res.return !== RESPONSE.SUCCESS) {
        return;
      }
      
      setSelectedItem(res.userList && res.userList.length > 0 ? res.userList[0] : {});
      setItemList(res.userList && res.userList.length > 0 ? res.userList : []);
      setMainCurrentPage(1);
      setMainLastPage(res.lastPage);
      setMainTotalCount(res.dataCount);
    };
    fetchData();
  }, [mainStatusFilter, mainSortConfig.direction, mainSortConfig.key, mainSearchTerm]);

  useEffect(() => {
    if (!selectedItem.staffId) return;
    const fetchData = async () => {
      const params = {
        page: 1,
        isDesc: subSortConfig.direction,
        orderKey: subSortConfig.key,
        status: subStatusFilter,
        name: subSearchTerm,
      }
      
      const res = await requestApiLoad(`/staff/getReport/${selectedItem.staffId}`, params, setIsLoading);
      if (res.return !== RESPONSE.SUCCESS) {
        return;
      }
  
      setReportItem(res.report);
      changeScreenMode(MODE.REPORT);
      setSubCurrentPage(1);
      setSubLastPage(res.report.lastPage);
      setSubTotalCount(res.report.dataCount);
    };
    fetchData();
    // eslint-disable-next-line
  }, [subStatusFilter, subSortConfig.direction, subSortConfig.key, subSearchTerm]);

  useEffect(() => {
    if (!isInit) {
      return;
    }

    if (itemList.length > 0) {
      if (size.width > 1200) {
        handleEdit(null, itemList[0]);
        changeScreenMode(MODE.EDIT);
      } else {
        changeScreenMode(MODE.NONE);
      }
      setIsInit(false);
    }
    // eslint-disable-next-line
  }, [itemList]);

  useEffect(() => {
    if (!selectedItem) {
      setSelectedItem({});
      return;
    }
    createEditTable();
    // eslint-disable-next-line
  }, [selectedItem]);

  // ------------------------------------------------------------------------------------
  // 画面モード
  // ------------------------------------------------------------------------------------
  const MODE = {
    NONE: 'NONE',
    ADD: 'ADD',
    EDIT: 'EDIT',
    REPORT: 'REPORT',
  }

  const changeScreenMode = (mode) => {
    switch (mode) {
      case MODE.ADD:
        setShowAdd(true);
        setShowEdit(false);
        setShowReport(false);
        break;
      
      case MODE.EDIT:
        setShowAdd(false);
        setShowEdit(true);
        setShowReport(false);
        break;
      
      case MODE.REPORT:
        setShowAdd(false);
        setShowEdit(false);
        setShowReport(true);
        break;
      
      case MODE.NONE:
      default:
        setShowAdd(false);
        setShowEdit(false);
        setShowReport(false);
        break;
    }
  }

  // ------------------------------------------------------------------------------------
  // ページネーション
  // ------------------------------------------------------------------------------------
  const handleMainPageChange = async (pageNumber) => {
    setMainCurrentPage(pageNumber);
    
    const params = {
      page: pageNumber,
      isDesc: mainSortConfig.direction,
      orderKey: mainSortConfig.key,
      status: mainStatusFilter,
      name: mainSearchTerm,
    }
    
    const res = await requestApiLoad('/staff/get', params, setIsLoading);
    if (res.return !== RESPONSE.SUCCESS) {
      return;
    }

    setSelectedItem(res.userList[0]);
    setItemList(res.userList);
    setMainLastPage(res.lastPage);
    setMainTotalCount(res.dataCount);
  };
  
  const handleSubPageChange = async (pageNumber) => {
    setSubCurrentPage(pageNumber);
    
    const params = {
      page: pageNumber,
      isDesc: subSortConfig.direction,
      orderKey: subSortConfig.key,
      status: subStatusFilter,
      name: subSearchTerm,
    }
    
    const res = await requestApiLoad(`/staff/getReport/${selectedItem.staffId}`, params, setIsLoading);
    if (res.return !== RESPONSE.SUCCESS) {
      return;
    }

    setReportItem(res.report);
    setSubLastPage(res.report.lastPage);
    setSubTotalCount(res.report.dataCount);
  };

  // ------------------------------------------------------------------------------------
  // フィルター
  // ------------------------------------------------------------------------------------
  const handleMainStatusChange = async (event) => {
    const newStatusFilter = Number(event.target.value);
    setMainCurrentPage(1);
    setMainStatusFilter(newStatusFilter);
  };

  const handleSubStatusChange = async (event) => {
    const newStatusFilter = Number(event.target.value);
    setSubCurrentPage(1);
    setSubStatusFilter(newStatusFilter);
  };

  const sortedAndFilteredItems = useMemo(() => {
    let filteredItems = itemList || [];
    return filteredItems;
  }, [itemList]);

  // ------------------------------------------------------------------------------------
  // ソート
  // ------------------------------------------------------------------------------------
  const handleMainSortChange = async (event) => {
    const newSortConfig = !event ? { key: 'staffId', direction: true } : JSON.parse(event);
    setMainSortConfig(newSortConfig);
  };
  
  const handleSubSortChange = async (event) => {
    const newSortConfig = !event ? { key: 'jobId', direction: true } : JSON.parse(event);
    setSubSortConfig(newSortConfig);
  };

  // ------------------------------------------------------------------------------------
  // 検索
  // ------------------------------------------------------------------------------------
  const onMainClear = () => {
    setMainSearchTerm('');
  };

  const onSubClear = () => {
    setSubSearchTerm('');
  };

  // ------------------------------------------------------------------------------------
  // 新規追加
  // ------------------------------------------------------------------------------------
  const handleAdd = async () => {
    setAddItem({
      staffId: '',
      staffName: '',
      staffNameKana: '',
      birthday: '',
      mail: '',
      telephone: '',
      workName: '',
      sex: Number(SEX_TYPE.NONE),
      startDate: today,
      endDate: nextYearToday,
      employmentStartDate: '',
      employmentEndDate: '',
      groupId: null,
      groupName: '',
      groupNameKana: '',
      postalCode: '',
      address1: '',
      address2: '',
      note: '',
    });
    changeScreenMode(MODE.ADD);
  };

  const handleAddCancel = async (event) => {
    event.preventDefault();
    
    setItemList(itemList);
    setMainLastPage(mainLastPage);
    setMainTotalCount(mainTotalCount);
    setSelectedItem(itemList[0]);

    if(!itemList || itemList?.length === 0) {
      changeScreenMode(MODE.NONE);
    } else if (size.width <= 1200) {
      changeScreenMode(MODE.NONE);
    } else {
      changeScreenMode(MODE.EDIT);
    }
  }
  
  const handleAddSubmit = async (event) => {
    event.preventDefault();
    const params = {
      ...addItem,
      sex: Number(addItem.sex),
      postalCode: addItem.postalCode.replace(/-/g, ''),
    }

    // TODO: バリデーションチェック項目追加
    const validateStaffDataResult = ValidationCheck(params);
    if (validateStaffDataResult.error) {
      alert(validateStaffDataResult.message);
      return;
    }

    let isContinue = true;
    if (addItem.startDate === today) {
      if (!window.confirm(Message.CONFIRM_NOW_ADD_STAFF)) {
        isContinue = false;
      }
    } else {
      if (!window.confirm(Message.CONFIRM_SCHEDULE_ADD_STAFF)) {
        isContinue = false;
      }
    }

    if (isContinue) {
      const res = await requestApiLoadAndBottom('/staff/add',
        params, setIsLoading, bottomSheetRef, Message.BS_STAFF_ADD_SUCCESS);
      if (res.return !== RESPONSE.SUCCESS) {
        if (res.errorCode === 'W-ST2-004') {
          alert('このメールアドレスは使用できません。');
        }
        if (res.errorCode === 'W-ST2-005') {
          alert('アクティブなスタッフ数がプランの上限に達しています。');
        }
        return;
      }
      setFirstItem(res);

      if (size.width <= 1200) {
        changeScreenMode(MODE.NONE);
      } else {
        changeScreenMode(MODE.EDIT);
      }
    }
  }

  // ------------------------------------------------------------------------------------
  // 編集
  // ------------------------------------------------------------------------------------
  const handleEdit = async (e, item) => {
    if (e && e.stopPropagation) {
      e.stopPropagation();
    }

    if (!item) { return; }
    setSelectedItem({
      ...item,
      sex: Number(item.sex),
      postalCode: item.postalCode ? formatPostalCode(item.postalCode) : '',
    });

    changeScreenMode(MODE.EDIT);
  }

  const handleEditSubmit = async (event) => {
    event.preventDefault();
    const params = {
      ...selectedItem,
      sex: Number(selectedItem.sex),
      postalCode: selectedItem.postalCode.replace(/-/g, ''),
    }

    const validateStaffDataResult = ValidationCheck(params, true);
    if (validateStaffDataResult.error) {
      alert(validateStaffDataResult.message);
      return;
    }

    let isContinue = true;
    if (selectedItem.startDate === today) {
      if (!window.confirm(Message.CONFIRM_NOW_ADD_STAFF)) {
        isContinue = false;
      }
    } else {
      if (!window.confirm(Message.CONFIRM_SCHEDULE_ADD_STAFF)) {
        isContinue = false;
      }
    }

    if (isContinue) {
      const res = await requestApiLoadAndBottom(`/staff/update/${selectedItem.staffId}`,
        params, setIsLoading, bottomSheetRef, Message.BS_STAFF_UPDATE_SUCCESS);
      if (res.return !== RESPONSE.SUCCESS) {
        return;
      }
      setFirstItem(res);
      
      if (size.width <= 1200) {
        changeScreenMode(MODE.NONE);
      } else {
        changeScreenMode(MODE.EDIT);
      }
    }
  }

  const handleStopSubmit = async (event) => {
    event.preventDefault();
    
    let confirmMessage = '';
    let bsMessage = '';
    if (selectedItem.status === STAFF_STATUS.ACTIVE) {
      confirmMessage = Message.CONFIRM_NOW_STOP_ACCOUNT;
      bsMessage = Message.BS_STAFF_STOP_SUCCESS;
    } else if(selectedItem.status === STAFF_STATUS.SCHEDULED) {
      confirmMessage = Message.CONFIRM_NOW_DELETE_ACCOUNT;
      bsMessage = Message.BS_STAFF_DELETE_SUCCESS;
    }
    
    if (!window.confirm(confirmMessage)) { return; }
    const res = await requestApiLoadAndBottom(`/staff/stop/${selectedItem.staffId}`,
      {}, setIsLoading, bottomSheetRef, bsMessage);
    
    if (res.return !== RESPONSE.SUCCESS) {
      return;
    }
    setFirstItem(res);
    
    if (size.width <= 1200) {
      changeScreenMode(MODE.NONE);
    } else {
      changeScreenMode(MODE.EDIT);
    }
  }

  // ------------------------------------------------------------------------------------
  // 集計
  // ------------------------------------------------------------------------------------
  const handleReport = async (e, item) => {
    e.stopPropagation();
    if (!item) { return; }
    setSelectedItem(item);

    const params = {
      page: 1,
      isDesc: subSortConfig.direction,
      orderKey: subSortConfig.key,
      status: subStatusFilter,
      name: subSearchTerm,
    }
    
    const res = await requestApiLoad(`/staff/getReport/${item.staffId}`, params, setIsLoading);
    if (res.return !== RESPONSE.SUCCESS) {
      return;
    }

    setReportItem(res.report);
    changeScreenMode(MODE.REPORT);
    setSubCurrentPage(1);
    setSubLastPage(res.report.lastPage);
    setSubTotalCount(res.report.dataCount);
  }

  // ------------------------------------------------------------------------------------
  // PDF出力
  // ------------------------------------------------------------------------------------
  const handlePDFOutput = async () => {
    const params = {
      isDesc: false,
      orderKey: 'staffName',
      status: 0,
    }

    const res = await requestApiLoad('/staff/get', params, setIsLoading);
    if (res.return !== RESPONSE.SUCCESS) {
      return;
    }
    const outputData = res.userList;

    if (!window.confirm('スタッフ一覧のPDF出力をしますか？')) {
      return;
    }
    if (!outputData || outputData.length === 0) {
      alert('出力するスタッフデータがありません');
      return;
    }

    const itemsPerPage = 9; // 1ページあたりの項目数
    const firstPageItems = itemsPerPage - 2; // 1ページ目の項目数
    const totalPages = Math.ceil((outputData.length - firstPageItems) / itemsPerPage) + 1;

    const pdf = new jsPDF({
      orientation: 'landscape',
      unit: 'mm',
      format: 'a4'
    });

    for (let pageNum = 0; pageNum < totalPages; pageNum++) {
      let startIndex, endIndex;
      if (pageNum === 0) {
        startIndex = 0;
        endIndex = firstPageItems;
      } else {
        startIndex = firstPageItems + (pageNum - 1) * itemsPerPage;
        endIndex = Math.min(startIndex + itemsPerPage, outputData.length);
      }
      const pageItems = outputData.slice(startIndex, endIndex);

      const element = document.createElement('div');
      document.body.appendChild(element);
      const root = ReactDOM.createRoot(element);
      root.render(
        <StaffList
          data={pageItems}
          showHeader={pageNum === 0}
          currentPage={pageNum + 1}
          totalPages={totalPages}
        />
      );
      await new Promise(resolve => setTimeout(resolve, 1000));

      const canvas = await html2canvas(element, {
        scale: 3.8,
        useCORS: true,
        allowTaint: false,
        width: 297 * 3.8,
        height: 210 * 3.8,
      });

      const imgData = canvas.toDataURL("image/png", 1.0);

      if (pageNum > 0) {
        pdf.addPage();
      }

      pdf.addImage(imgData, "PNG", 0, 0, 297, 210);

      root.unmount();
      document.body.removeChild(element);
    }

    pdf.save(`スタッフ一覧_${getCurrentDateString()}.pdf`);
  };

  // ------------------------------------------------------------------------------------
  // CSV出力
  // ------------------------------------------------------------------------------------
  const handleCSVOutput = async () => {
    if (!window.confirm('スタッフ一覧のCSV出力をしますか？')) {
      return;
    }

    const params = {
      isDesc: false,
      orderKey: 'staffName',
      status: 0,
    }

    const res = await requestApiLoad('/staff/get', params, setIsLoading);
    if (res.return !== RESPONSE.SUCCESS) {
      return;
    }
    const outputData = res.userList;

    if (!outputData || outputData.length === 0) return;

    const csvData = [
      ['スタッフ名', 'スタッフ名（かな）', 'メールアドレス', '性別', '生年月日', '業務内容', '雇用開始日', '雇用終了日', '電話番号', '郵便番号', '住所1', '住所2', '備考', 'グループID', 'グループ名', 'ステータス'],
      ...outputData.map(item => [
        item.staffName,
        item.staffNameKana,
        item.mail,
        Number(item.sex) === 1 ? '男性' : Number(item.sex) === 2 ? '女性' : '無回答',
        item.birthday,
        item.workName,
        item.employmentStartDate,
        item.employmentEndDate,
        item.telephone,
        item.postalCode,
        item.address1,
        item.address2,
        item.note,
        item.groupId,
        item.groupName,
        Number(item.status) === 1 ? '利用中' : Number(item.status) === 2 ? '準備中' : '停止中',
      ])
    ];

    const csv = unparse(csvData);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.setAttribute('download', `スタッフ一覧_${getCurrentDateString()}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  // ------------------------------------------------------------------------------------
  // その他
  // ------------------------------------------------------------------------------------ 
  const getStatusText = (item) => {
    switch (item.status) {
      case STAFF_STATUS.ACTIVE:
        return ' (利用中)';
      
      case STAFF_STATUS.SCHEDULED:
        return ' (準備中)';
      
      default:
        return ' (停止中)';
    }
  }

  const setFirstItem = (res) => {
    setItemList(res.userList);
    setMainLastPage(res.lastPage);
    setMainTotalCount(res.dataCount);
    setSelectedItem(res.userList[0]);
    // setEditItem(res.userList[0]);
    
    setMainCurrentPage(1);
    setMainStatusFilter(Number(STAFF_STATUS.ALL));
    setMainSearchTerm('');
    setMainSortConfig({ key: 'staffId', direction: true });
    resetScroll();
    resetScrollMain();
  }

  const resetScroll = () => {
    const scrollForm = document.querySelector('.scroll-form');
    if (scrollForm) {
      scrollForm.scrollTop = 0;
    }
  }

  const resetScrollMain = () => {
    const scrollForm = document.querySelector('.card-list');
    if (scrollForm) {
      scrollForm.scrollTop = 0;
    }
  }

  // ------------------------------------------------------------------------------------
  // ValidationCheck
  // ------------------------------------------------------------------------------------
  const ValidationCheck = (params, isEdit = false) => {
    if (!isEdit) {
      // スタッフ名
      const staffNameResult = Validators.validateUserName(params.staffName, USER_TYPE.STAFF);
      if (staffNameResult.error) {
        return { message: staffNameResult.message, error: true };
      }
      // スタッフ名（かな）
      const staffNameKanaResult = Validators.validateUserNameKana(params.staffNameKana, USER_TYPE.STAFF);
      if (staffNameKanaResult.error) {
        return { message: staffNameKanaResult.message, error: true };
      }
      // メールアドレス
      const mailResult = Validators.validateMail(params.mail);
      if (mailResult.error) {
        return { message: mailResult.message, error: true };
      }
      // 利用開始日
      const useStartDateResult = Validators.validateStartDate(params.startDate);
      if (useStartDateResult.error) {
        return { message: useStartDateResult.message, error: true };
      }
    }

    if (params.endDate) {
      // 利用終了日
      const useEndDateResult = Validators.validateEndDate(params.endDate);
      if (useEndDateResult.error) {
        return { message: useEndDateResult.message, error: true };
      }
      
      if (params.startDate) {
        // 利用開始日 & 利用終了日
        const useStartDateAndEndDateResult = Validators.validateUseDate(params.startDate, params.endDate);
        if (useStartDateAndEndDateResult.error) {
          return { message: useStartDateAndEndDateResult.message, error: true };
        }
      }
    }
    
    if (params.employmentStartDate) {
      // 雇用開始日
      const employmentStartDateResult = Validators.validateDateRange(params.employmentStartDate);
      if (employmentStartDateResult.error) {
        return { message: employmentStartDateResult.message, error: true };
      }
    }
    if (params.employmentEndDate) {
      // 雇用終了日
      const employmentEndDateResult = Validators.validateDateRange(params.employmentEndDate);
      if (employmentEndDateResult.error) {
        return { message: employmentEndDateResult.message, error: true };
      }

      if (params.employmentStartDate) {
        // 雇用開始日 & 雇用終了日
        const useEmploymentStartDateAndEndDateResult = Validators.validateUseDate(params.employmentStartDate, params.employmentEndDate);
        if (useEmploymentStartDateAndEndDateResult.error) {
          return { message: useEmploymentStartDateAndEndDateResult.message, error: true };
        }
      }
    }
    if (params.workName) {
      // 業務内容
      const workNameResult = Validators.validateWorkName(params.workName);
      if (workNameResult.error) {
        return { message: workNameResult.message, error: true };
      }
    }

    if (params.birthday) {
      // 誕生日
      const birthdayResult = Validators.validateBirthday(params.birthday);
      if (birthdayResult.error) {
        return { message: birthdayResult.message, error: true };
      }
    }

    // 性別
    const sexResult = Validators.validateSex(params.sex);
    if (sexResult.error) {
      return { message: sexResult.message, error: true };
    }

    if (params.telephone) {
      // 電話番号
      const telephoneResult = Validators.validatePhoneNumber(params.telephone);
      if (telephoneResult.error) {
        return { message: telephoneResult.message, error: true };
      }
    }

    if (params.postalCode) {
      // 郵便番号
      const postalCodeResult = Validators.validatePostalCode(params.postalCode);
      if (postalCodeResult.error) {
        return { message: postalCodeResult.message, error: true };
      }
    }

    if (params.address1) {
      // 住所1
      const address1Result = Validators.validateAddress1(params.address1);
      if (address1Result.error) {
        return { message: address1Result.message, error: true };
      }
    }

    if (params.address2) {
      // 住所2
      const address2Result = Validators.validateAddress2(params.address2);
      if (address2Result.error) {
        return { message: address2Result.message, error: true };
      }
    }
    
    if (params.note) {
      // 備考
      const noteResult = Validators.validateNote(params.note);
      if (noteResult.error) {
        return { message: noteResult.message, error: true };
      }
    }

    return { message: '', error: false };
  };

  // ------------------------------------------------------------------------------------
  // 一覧作成
  // ------------------------------------------------------------------------------------ 
  const createTable = (itemList) => {
    return (
      <div className="card-list">
        {itemList.length === 0 ? (
          <div className="no-data">データがありません</div>
        ) : (
          itemList.map((item) => (
            <div className="card" key={item.staffId} onClick={(e) => handleEdit(e, item)}>
              <div className="card-contents" id="staff-setting-card-contents">
                <div className="card-contents-left">
                  <h3>{item.staffName}</h3>
                  <span className="sub-text">{item.startDate} ~ {item.endDate}
                    {getStatusText(item)}
                  </span>
                </div>
                <div className="card-footer">
                  {/* 集計 */}
                  <button className="blue" disabled={item.testStatus === TEST_STATUS.NONE} onClick={(e) => handleReport(e, item)} title="集計">
                    <svg
                      width="20px"
                      height="20px"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-labelledby="pollIconTitle"
                      stroke="var(--blue-font-color)"
                      strokeWidth="2.0"
                      strokeLinecap="square"
                      strokeLinejoin="miter"
                      color="var(--blue-font-color)">
                      <title id="pollIconTitle">集計</title>
                      <path d="M4 4V20" />
                      <path d="M4 6H15V10H4" />
                      <path d="M4 10H19V14H4" />
                      <path d="M4 14H12V18H4" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    );
  }

  // ------------------------------------------------------------------------------------
  // AddTable
  // ------------------------------------------------------------------------------------ 
  const createAddTable = () => {
    return (
      <>
        <div className="scroll-form">
          <StaffSettingForm
            formData={addItem}
            setFormData={setAddItem}
            isEdit={false}
          />
        </div>
        <div className="bottom-button">
          <button onClick={handleAddCancel}>
            キャンセル
          </button>
          <button className="blue" onClick={handleAddSubmit}>
            登録
          </button>
        </div>
      </>
    )
  }

  // ------------------------------------------------------------------------------------
  // EditTable
  // ------------------------------------------------------------------------------------ 
  const createEditTable = () => {
    return (
      <>
        <div className="scroll-form">
          <StaffSettingForm
            formData={selectedItem}
            setFormData={setSelectedItem}
            isEdit={true}
          />
        </div>
        <div className="bottom-button">
          {(Number(selectedItem.status) === STAFF_STATUS.ACTIVE || Number(selectedItem.status) === STAFF_STATUS.SCHEDULED) &&
            (new Date(selectedItem.endDate).getTime() !== new Date(today).getTime()) && (
              <button className="red" onClick={handleStopSubmit}>
                {Number(selectedItem.status) === STAFF_STATUS.ACTIVE ? '利用停止' : '削除'}
              </button>
            )}
          {selectedItem.status !== STAFF_STATUS.INACTIVE &&
            <button className="blue" onClick={handleEditSubmit}>
              更新
            </button>
          }
        </div>
      </>
    )
  }

  // ------------------------------------------------------------------------------------
  // ReportTable
  // ------------------------------------------------------------------------------------ 
  const createReportTable = () => {
    return (
      <div className="card-list">
        {!reportItem || reportItem.jobList.length === 0 || !Array.isArray(reportItem.jobList) ? (
          <div className="no-data">データがありません</div>
        ) : (
          reportItem.jobList.map((job) => (
            <div className="card" key={job.jobId}>
              <div className="card-contents">
                <div className="card-contents-left">
                  <h3 className="card-title">
                    <span>
                      {job.fileType === FILE_TYPE.PDF && (
                        <svg
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          width="20px"
                          height="20px"
                          viewBox="0 0 24 24"
                          aria-labelledby="stickerIconTitle"
                          stroke="var(--blue-font-color)"
                          strokeWidth="1.8"
                          strokeLinecap="square"
                          strokeLinejoin="miter"
                          fill="none"
                          color="var(--blue-font-color)">
                          <title id="stickerIconTitle">PDF</title>
                          <path d="M21,3 L21,11 C21,16.5228475 16.5228475,21 11,21 L3,21 L3,3 L21,3 Z" />
                          <path d="M9,21 C11.6666667,21 13,19.6666667 13,17 C13,17 13,15.6666667 13,13 L17,13 C19.6666667,13 21,11.6666667 21,9" />
                        </svg>
                      )}
                      {job.fileType === FILE_TYPE.VIDEO && (
                        <svg
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          width="19px"
                          height="20px"
                          viewBox="0 0 24 24"
                          aria-labelledby="videoIconTitle"
                          stroke="var(--blue-font-color)"
                          strokeWidth="1.8"
                          strokeLinecap="square"
                          strokeLinejoin="miter"
                          fill="none"
                          color="var(--blue-font-color)">
                          <title id="videoIconTitle">Video</title>
                          <polygon points="18 12 9 16.9 9 7" />
                          <circle cx="12" cy="12" r="10" />
                        </svg>
                      )}
                    </span>
                    {job.jobName}
                    {job.groupName && (
                      <span>
                        <svg
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          width="17px"
                          height="17px"
                          viewBox="0 0 24 24"
                          aria-labelledby="peopleIconTitle"
                          stroke="var(--blue-font-color)"
                          strokeWidth="1.7"
                          strokeLinecap="square"
                          strokeLinejoin="miter"
                          fill="none"
                          color="var(--blue-font-color)">
                          <title id="peopleIconTitle">グループ</title>
                          <path d="M1 18C1 15.75 4 15.75 5.5 14.25 6.25 13.5 4 13.5 4 9.75 4 7.25025 4.99975 6 7 6 9.00025 6 10 7.25025 10 9.75 10 13.5 7.75 13.5 8.5 14.25 10 15.75 13 15.75 13 18M12.7918114 15.7266684C13.2840551 15.548266 13.6874862 15.3832994 14.0021045 15.2317685 14.552776 14.9665463 15.0840574 14.6659426 15.5 14.25 16.25 13.5 14 13.5 14 9.75 14 7.25025 14.99975 6 17 6 19.00025 6 20 7.25025 20 9.75 20 13.5 17.75 13.5 18.5 14.25 20 15.75 23 15.75 23 18" />
                          <path strokeLinecap="round" d="M12,16 C12.3662741,15.8763472 12.6302112,15.7852366 12.7918114,15.7266684" />
                        </svg>
                      </span>
                    )}
                  </h3>
                  <span className="sub-text">
                    正解数：{job.correctCount !== null && job.correctCount >= 0 ? `${job.correctCount}/${job.questionCount}(${job.correctRate}%)` : '-'}
                  </span>
                </div>
                <div className="card-footer">
                 
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    )
  }

  // ------------------------------------------------------------------------------------
  // レンダリング
  // ------------------------------------------------------------------------------------
  return (
    <div className="view-contents" id="staff-setting">
      <Loading isLoading={isLoading} />
      <BottomSheet ref={bottomSheetRef} />

      <div className="main-contents">
        {size.width > 1200 && (<h2 className="page-title">スタッフ設定</h2>)}
        <div className="header-contents">
          <div className="search-bar">
            <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
              <input
                type="text"
                placeholder="スタッフ名で検索"
                value={mainSearchTerm || ''}
                onChange={(e) => setMainSearchTerm(e.target.value)}
                maxLength={20}
                style={{
                  backgroundImage: 'url(/images/search.png)',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: '10px center',
                  backgroundSize: '20px 20px',
                  paddingLeft: '40px',
                  paddingRight: mainSearchTerm ? '30px' : '10px'
                }}
              />
              {mainSearchTerm && (
                <button
                  onClick={onMainClear}
                  style={{
                    position: 'absolute',
                    right: '10px',
                    background: 'none',
                    border: 'none',
                    cursor: 'pointer',
                    fontSize: '16px',
                    color: '#878787'
                  }}>
                  ×
                </button>
              )}
            </div>
          </div>
          <div className="button-container">
            <OutputButton
              handlePDFOutput={handlePDFOutput}
              handleCSVOutput={handleCSVOutput}
            />
            <div className="add-new-item-button" onClick={handleAdd}>
              <svg
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                width="18px"
                height="18px"
                viewBox="0 0 24 24"
                aria-labelledby="plusIconTitle"
                stroke="var(--blue-font-color)"
                strokeWidth="1.8"
                strokeLinecap="square"
                strokeLinejoin="miter"
                fill="none"
                color="var(--blue-font-color)"
              >
                <title id="plusIconTitle">新規追加</title>
                <path d="M20 12L4 12M12 4L12 20" />
              </svg>
              <p className="hide-option">新規追加</p>
            </div>
          </div>
        </div>

        <div className="middle-contents">
          <div className="left-contents">
            <FilterButton
              statusFilter={mainStatusFilter}
              handleStatusChange={handleMainStatusChange}
              statusOptions={mainStatusOptions}
            />
            <SortingButton
              sortConfig={mainSortConfig}
              handleSortChange={handleMainSortChange}
              sortOptions={mainSortOptions}
            />
          </div>
          <Pagination
            totalPages={mainLastPage}
            currentPage={mainCurrentPage}
            onPageChange={handleMainPageChange}
            totalItems={mainTotalCount}
          />
        </div>

        {createTable(sortedAndFilteredItems)}
      </div>

      {size.width > 1200 ? (
        <>
          {showAdd && (
            <div className="sub-contents">
              <h2 className="page-title">スタッフ追加</h2>
              {createAddTable()}
            </div>
          )}

          {showEdit && (
            <div className="sub-contents">
              <h2 className="page-title">{selectedItem.staffName}</h2>
              {createEditTable()}
            </div>
          )}

          {showReport && (
            <div className="sub-contents">
              <h2 className="page-title">{selectedItem.staffName}</h2>
              <div className="header-contents">
                <div className="search-bar">
                  <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                    <input
                      type="text"
                      placeholder="研修名で検索"
                      value={subSearchTerm}
                      onChange={(e) => setSubSearchTerm(e.target.value)}
                      maxLength={20}
                      style={{
                        backgroundImage: 'url(/images/search.png)',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: '10px center',
                        backgroundSize: '20px 20px',
                        paddingLeft: '40px',
                        paddingRight: subSearchTerm ? '30px' : '10px'
                      }}
                    />
                    {subSearchTerm && (
                      <button
                        onClick={onSubClear}
                        style={{
                          position: 'absolute',
                          right: '10px',
                          background: 'none',
                          border: 'none',
                          cursor: 'pointer',
                          fontSize: '16px',
                          color: '#878787'
                        }}>
                        ×
                      </button>
                    )}
                  </div>
                </div>
              </div>

              <div className="middle-contents">
                <div className="left-contents">
                  <FilterButton
                    statusFilter={subStatusFilter}
                    handleStatusChange={handleSubStatusChange}
                    statusOptions={subStatusOptions}
                  />
                  <SortingButton
                    sortConfig={subSortConfig}
                    handleSortChange={handleSubSortChange}
                    sortOptions={subSortOptions}
                  />
                </div>
                <Pagination
                  totalPages={subLastPage}
                  currentPage={subCurrentPage}
                  onPageChange={handleSubPageChange}
                  totalItems={subTotalCount}
                />
              </div>

              {createReportTable(reportItem)}
            </div>
          )}
        </>
      ) : (
        <>
          <Modal
            isOpen={showAdd}
            title="スタッフ追加"
            onAction={handleAddSubmit}
            actionButtonText="登録"
            closeButtonText="閉じる"
            closeModal={() => setShowAdd(false)}
          >
            <div className="sub-contents">
              {createAddTable()}
            </div>
          </Modal>

          <Modal
            isOpen={showEdit}
            title={selectedItem.staffName || ''}
            actionButtonText={selectedItem.status !== STAFF_STATUS.INACTIVE ? "更新" : ""}
            onAction={handleEditSubmit}
            actionSubButtonText={(Number(selectedItem.status) === STAFF_STATUS.ACTIVE) &&
              (new Date(selectedItem.endDate).getTime() !== new Date(today).getTime()) ? "利用停止" :
              (Number(selectedItem.status) === STAFF_STATUS.SCHEDULED) ? "削除" : ""}
            onSubAction={handleStopSubmit}
            closeButtonText="閉じる"
            closeModal={() => setShowEdit(false)}
          >
            <div className="sub-contents">
              {createEditTable()}
            </div>
          </Modal>
            
          <Modal
            isOpen={showReport}
            title={selectedItem.staffName}
            closeModal={() => setShowReport(false)}
            closeButtonText="閉じる"
          >
            <div className="sub-contents">
              <div className="header-contents">
                <div className="search-bar">
                  <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                    <input
                      type="text"
                      placeholder="研修名で検索"
                      value={subSearchTerm}
                      onChange={(e) => setSubSearchTerm(e.target.value)}
                      maxLength={20}
                      style={{
                        backgroundImage: 'url(/images/search.png)',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: '10px center',
                        backgroundSize: '20px 20px',
                        paddingLeft: '40px',
                        paddingRight: subSearchTerm ? '30px' : '10px'
                      }}
                    />
                    {subSearchTerm && (
                      <button
                        onClick={onSubClear}
                        style={{
                          position: 'absolute',
                          right: '10px',
                          background: 'none',
                          border: 'none',
                          cursor: 'pointer',
                          fontSize: '16px',
                          color: '#878787'
                        }}>
                        ×
                      </button>
                    )}
                  </div>
                </div>
              </div>

              <div className="middle-contents">
                <div className="left-contents">
                  <FilterButton
                    statusFilter={subStatusFilter}
                    handleStatusChange={handleSubStatusChange}
                    statusOptions={subStatusOptions}
                  />
                  <SortingButton
                    sortConfig={subSortConfig}
                    handleSortChange={handleSubSortChange}
                    sortOptions={subSortOptions}
                  />
                </div>
                <Pagination
                  totalPages={subLastPage}
                  currentPage={subCurrentPage}
                  onPageChange={handleSubPageChange}
                  totalItems={subTotalCount}
                />
              </div>
              {createReportTable(reportItem)}
            </div>
          </Modal>
        </>
      )}
    </div>
  )
}

export default StaffSetting;